<template>
  <div class="myAssTag">
    <!-- 我的考核指标 -->
    <van-nav-bar :title="$t('myAssTaget.tagTxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content">
      <van-tabs v-model="activeName"
                color="#2B8DF0"
                title-active-color="#2B8DF0"
                @change="changeActive">
        <van-tab v-if="$t('myAssTaget.tagTxt2')"
                 :title="$t('myAssTaget.tagTxt2')"
                 name="360Tag"></van-tab>
        <van-tab v-if="$t('myAssTaget.tagTxt3')"
                 :title="$t('myAssTaget.tagTxt3')"
                 name="KPITag"></van-tab>
      </van-tabs>
      <van-pull-refresh v-model="isLoadingAwait"
                        @refresh="onRefreshAwait">
        <van-list v-model="loadingAwait"
                  :finished="finishedAwait"
                  finished-text="没有更多了"
                  @load="onLoadAwait">
          <div class="zb_group"
               v-for="(item, index) in assessList"
               :key="index"
               @click="goToList(item)">
            <van-cell class="ass_cell"
                      center
                      :title="item.khtimename"
                      :label="activeName == '360Tag' ? item.khname : item.planname"
                      is-link />
            <div class="ass_right">
              <div class="status_txt"
                   :class="
                  item.checkflag == 3
                    ? 'agree'
                    : item.status == 4
                    ? 'un_agree'
                    : item.status == 1
                    ? 'toBe_submit'
                    : 'assing'
                ">
                <span>{{
                  item.checkflag == 1
                    ? "待提交"
                    : item.checkflag == 2
                    ? "审核中"
                    : item.checkflag == 3
                    ? "同意"
                    : item.checkflag == 4
                    ? "不同意"
                    : ""
                }}</span>
              </div>
              <!-- <img src="@/assets/img/360ty.png"
                   alt="" /> -->
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- <div class="footer">
      <van-button type="info"
                  size="small"
                  @click="goExamine">发起指标申请</van-button>
    </div> -->
  </div>
</template>

<script>
import { Toast } from "vant";
import { getMy360KhList, getMyKpiKhList } from "@api/wxjx.js";
export default {
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
    return {
      userInfo,
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      assessList: [],
      activeName: "360Tag",
    };
  },
  created () { },
  //beforeRouteLeave
  beforeRouteEnter (to, from, next) {
    let is360 = true;
    if (from.name == "jxMyAssTargetApply")    {
      is360 = from.query.is360;
    }
    next((vm) => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (is360 == true || is360 == "true")      {
        vm.activeName = "360Tag";
      } else      {
        vm.activeName = "KPITag";
      }
    });
  },
  methods: {
    onClickLeft () {
      this.$router.push("/home");
    },
    // 获取“我的360指标”记录列表
    getMy360KhListData () {
      let _this = this;
      getMy360KhList({ empid: this.userInfo.empid }).then((res) => {
        if (res.data.length == 0)        {
          _this.finishedAwait = true;
          // 无数据
        } else        {
          _this.assessList = res.data;
          _this.loadingAwait = false;
          _this.finishedAwait = true;
        }
      });
    },
    // 获取“我的KPI指标”记录列表
    getMyKpiKhListData () {
      let _this = this;
      getMyKpiKhList({ empid: this.userInfo.empid }).then((res) => {
        if (res.data.length == 0)        {
          _this.finishedAwait = true;
          // 无数据
        } else        {
          _this.assessList = res.data;
          _this.loadingAwait = false;
          _this.finishedAwait = true;
        }
      });
    },
    onLoadAwait () {
      if (this.isLoadingAwait)      {
        this.assessList = [];
        this.isLoadingAwait = false;
      }
      if (this.activeName == "360Tag")      {
        this.getMy360KhListData();
      } else      {
        this.getMyKpiKhListData();
      }
    },
    onRefreshAwait () {
      this.assessList = [];
      this.finishedAwait = false;
      this.loadingAwait = true;
      this.onLoadAwait();
      // Toast('刷新成功')
    },
    // tab切换事件
    changeActive (name, title) {
      this.onRefreshAwait();
    },
    // 去列表
    goToList (item) {
      console.log(item, 'goToListgoToList')
      let usejj_forid = item.usejj_forid ? item.usejj_forid : false;
      let usezj_forid = item.usezj_forid ? item.usezj_forid : false;
      let title = this.$t('jxMyAssTargetDetails.txt16');
      let is360 = true;
      const mautoid = item.autoid;
      const checkflag = item.checkflag;
      let req_indicator_select = false
      if (this.activeName == "360Tag")      {
        title = this.$t('jxMyAssTargetDetails.txt16');
        is360 = true;
      } else      {
        title = this.$t('jxMyAssTargetDetails.txt17');
        is360 = false;
        req_indicator_select = item.req_indicator_select
      }
      this.$router.push({
        path: "/jxMyAssTargetApply",
        query: {
          title: title,
          is360: is360,
          mautoid: mautoid,
          checkflag: checkflag,
          usejj_forid: usejj_forid,
          usezj_forid: usezj_forid,
          lastscore: item.lastscore,
          req_indicator_select: req_indicator_select
        },
      });
    },
    // 发起指标申请
    goExamine () {
      let title = this.$t('jxMyAssTargetDetails.txt16');
      let is360 = true;
      const mautoid = 0;
      const checkflag = 1;
      if (this.activeName == "360Tag")      {
        title = this.$t('jxMyAssTargetDetails.txt16');
        is360 = true;
      } else      {
        title = this.$t('jxMyAssTargetDetails.txt17');
        is360 = false;
      }
      this.$router.push({
        path: "/jxMyAssTargetApply",
        query: {
          title: title,
          is360: is360,
          mautoid: mautoid,
          checkflag: checkflag,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myAssTag {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    // overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    /deep/.van-tabs {
      .van-tab {
        .van-tab__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
    .van-pull-refresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 120px);
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .zb_group:first-of-type {
      margin-top: 0;
    }
    .zb_group {
      display: flex;
      margin: 30px 0;
      .ass_cell {
        .van-cell__title {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          // line-height: 54px;
          color: #000000;
          .van-cell__label {
            font-size: 28px;
            font-weight: 500;
          }
        }
      }
      .ass_right {
        background-color: #fff;
        .status_txt {
          align-items: center;
          display: flex;
          justify-content: center;
          height: 164px;
          width: 100px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          // background-image: url("~@/assets/img/360ty.png");
          span {
            width: 1em;
          }
        }
        .agree {
          background: url("~@/assets/img/360ty.png") no-repeat;
          background-size: 100% 100%;
        }
        .un_agree {
          background: url("~@/assets/img/360bty.png") no-repeat;
          background-size: 100% 100%;
        }
        .toBe_submit {
          background: url("~@/assets/img/360dtj.png") no-repeat;
          background-size: 100% 100%;
        }
        .assing {
          background: url("~@/assets/img/360sh.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      width: 70%;
      border-radius: 12px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
}
</style>